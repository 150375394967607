var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h3',{staticClass:"grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.getConfig('call_for_funds.labels.call_for_funds_title'))+" ")]),_c('p',{staticClass:"subtitle-1 grey--text text--darken-2",domProps:{"innerHTML":_vm._s(_vm.getConfig('call_for_funds.labels.call_for_funds_intro'))}}),_c('v-chip',{ref:"productChip",staticClass:"ma-0 pa-1",attrs:{"color":"app-green","small":"","label":"","dark":""}},[_vm._v(_vm._s(_vm.selectedSale.product.reference))]),_c('v-chip',{ref:"programChip",staticClass:"ma-0 pa-1",attrs:{"color":"app-green","small":"","label":"","dark":""}},[_vm._v(_vm._s(_vm.selectedSale.program.title))]),_vm._v(". "),_c('v-row',[(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ProgramSummary')],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"pt-4"},[_c('v-card-title',{staticClass:"py-0 mb-2"},[_c('v-spacer'),_c('h6',{staticClass:"font-weight-regular mr-2"},[_vm._v(" Votre acquisition ")]),_c('span',{staticClass:"font-weight-bold"},[_c('number',{attrs:{"to":_vm.getSalePriceTTC(_vm.selectedSale),"format":_vm.$options.filters.toCurrencyString,"duration":1.5,"easing":"Power4.easeOut"}})],1),_c('v-spacer')],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"rotate":"-90","size":100,"width":15,"value":_vm.displayPercents ? _vm.totalCalledPercent : 0,"color":"app-green"}},[_c('number',{attrs:{"to":_vm.totalCalledPercent,"format":_vm.$options.filters.floor,"duration":1.5,"easing":"Power4.easeOut"}}),_vm._v(" % ")],1),_c('br'),_c('span',{staticClass:"caption"},[_vm._v(" Montant appelé ")]),_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-weight-bold"},on),[_c('number',{attrs:{"to":_vm.getSaleTotalCalled(_vm.selectedSale),"format":_vm.$options.filters.toCurrencyString,"duration":1.5,"easing":"Power4.easeOut"}})],1)]}}])},[_vm._v(" Sur un total de "+_vm._s(_vm._f("toCurrencyString")(_vm.getSalePriceTTC(_vm.selectedSale)))+" ")])],1),_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"rotate":"-90","size":100,"width":15,"value":_vm.displayPercents ? _vm.totalPaidPercent : 0,"color":"app-red"}},[_c('number',{attrs:{"to":_vm.totalPaidPercent,"format":_vm.$options.filters.floor,"duration":1.5,"easing":"Power4.easeOut"}}),_vm._v(" % ")],1),_c('br'),_c('span',{staticClass:"caption"},[_vm._v(" Montant appelé réglé ")]),_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-weight-bold"},on),[_c('number',{attrs:{"to":_vm.totalPaid,"format":_vm.$options.filters.toCurrencyString,"duration":1.5,"easing":"Power4.easeOut"}})],1)]}}])},[_vm._v(" Sur un total de "+_vm._s(_vm._f("toCurrencyString")(_vm.getSaleTotalCalled(_vm.selectedSale)))+" ")])],1),_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"rotate":"-90","size":100,"width":15,"value":_vm.displayPercents ? _vm.amountToPayPercent : 0,"color":"app-blue"}},[_c('number',{attrs:{"to":_vm.amountToPayPercent,"format":_vm.$options.filters.floor,"duration":1.5,"easing":"Power4.easeOut"}}),_vm._v(" % ")],1),_c('br'),_c('span',{staticClass:"caption"},[_vm._v(" Reste à payer ")]),_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-weight-bold"},on),[_c('number',{attrs:{"to":_vm.getSalePriceTTC(_vm.selectedSale) - _vm.totalPaid,"format":_vm.$options.filters.toCurrencyString,"duration":1.5,"easing":"Power4.easeOut"}})],1)]}}])},[_vm._v(" Sur un total de "+_vm._s(_vm._f("toCurrencyString")(_vm.getSalePriceTTC(_vm.selectedSale)))+" ")])],1)],1),_c('v-timeline',_vm._l((_vm.getTimeline()),function(item){return _c('v-timeline-item',{key:item.id,class:{ 'text-right': _vm.isItemLeft(item) },attrs:{"color":_vm.getItemDotColor(item),"left":_vm.isItemLeft(item),"right":!_vm.isItemLeft(item),"icon":_vm.getItemDotIcon(item),"fill-dot":"","small":_vm.isItemDotSmall(item)},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [(item.itemType === 'callForFunds' && _vm.getSaleCallForFundsDocument(_vm.selectedSale, item.id))?[_c('v-btn',{attrs:{"href":_vm.getDocumentUrl(_vm.getSaleCallForFundsDocument(_vm.selectedSale, item.id).id),"target":"_blank","icon":""}},[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v(" mdi-file-pdf-box ")])],1)]:_vm._e()]},proxy:true}],null,true)},[(item.itemType === 'callForFunds')?[(item.fundcall.id || item.type === 1)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-weight-bold grey--text text--darken-2"},on),[(item.type === 1)?[_vm._v(" "+_vm._s(_vm._f("toDate")(_vm.selectedSale.signeddate))+" ")]:[_vm._v(" "+_vm._s(_vm._f("toDate")(item.fundcall.dateSent))+" ")]],2)]}}],null,true)},[(item.type === 1)?[_vm._v(" Date de signature contrat ")]:[_vm._v(" Date d'envoi ")]],2)]:_vm._e(),_c('br'),_c('span',{staticClass:"font-weight-bold"},[(item.fundcall.amount)?[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(item.fundcall.amount))+" ")]:_vm._e(),_c('v-chip',{staticClass:"ml-2 caption",attrs:{"color":"app-blue","small":"","label":"","dark":""}},[_vm._v(" "+_vm._s(_vm._f("round")(item.percent))+" % ")])],2),_c('br'),_c('span',{staticClass:"subtitle-2 font-weight-light"},[(item.steps)?[_vm._l((item.steps),function(step){return [_c('span',{key:step},[_vm._v(" "+_vm._s(step)+" ")]),_c('br',{key:step + 'br'})]})]:[_vm._v(" "+_vm._s(item.step)+" ")]],2)]:_vm._e(),(item.itemType === 'tma')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.date)?_c('span',_vm._g({staticClass:"font-weight-bold grey--text text--darken-2"},on),[_vm._v(" "+_vm._s(_vm._f("toDate")(item.date))+" ")]):_vm._e()]}}],null,true)},[_vm._v(" Date de demande ")]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(item.calledamount))+" "),_c('v-chip',{staticClass:"ml-2 caption",attrs:{"color":"app-blue","small":"","label":"","dark":""}},[_vm._v(" TMA ")])],1),_c('br'),_c('span',{staticClass:"subtitle-2 font-weight-light"},[_vm._v(" "+_vm._s(item.label)+" ")])]:_vm._e(),(item.itemType === 'payment')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.date)?_c('span',_vm._g({staticClass:"font-weight-bold grey--text text--darken-2"},on),[_vm._v(" "+_vm._s(_vm._f("toDate")(item.date))+" ")]):_vm._e()]}}],null,true)},[_vm._v(" Date d'encaissement ")]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(item.amount))+" ")]),_c('br'),_c('span',{staticClass:"subtitle-2 font-weight-light"},[(item.depositId)?[_vm._v(" Dépôt de garantie ")]:[(item.label)?[_vm._v(" "+_vm._s(item.label)+" "),(item.labelDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" fas fa-info-circle ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.labelDetail)+" ")]):_vm._e()]:[_vm._v(" Encaissement ")]]],2)]:_vm._e()],2)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }