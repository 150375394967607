<template>
    <v-container>
        <h3 class="grey--text text--darken-2"> {{ getConfig('call_for_funds.labels.call_for_funds_title') }} </h3>
        
        <p class="subtitle-1 grey--text text--darken-2" v-html="getConfig('call_for_funds.labels.call_for_funds_intro')">

        </p>

        <v-chip ref="productChip" color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.product.reference }}</v-chip>
        <v-chip ref="programChip" color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.program.title }}</v-chip>.

        <v-row>
            <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" lg="6">
                <ProgramSummary />
            </v-col>

            <v-col cols="12" lg="6">
                <v-card class="pt-4">
                    <v-card-title class="py-0 mb-2">
                        <v-spacer />
                        <h6 class="font-weight-regular mr-2">
                            Votre acquisition
                        </h6>
                        <span class="font-weight-bold">
                            <number :to="getSalePriceTTC(selectedSale)" :format="$options.filters.toCurrencyString" :duration="1.5" easing="Power4.easeOut" />
                        </span>
                        <v-spacer />
                    </v-card-title>

                    <v-row no-gutters>
                        <v-col class="text-center">
                            <v-progress-circular rotate="-90" :size="100" :width="15" :value="displayPercents ? totalCalledPercent : 0" color="app-green">
                                <number :to="totalCalledPercent" :format="$options.filters.floor" :duration="1.5" easing="Power4.easeOut" /> %
                            </v-progress-circular>
                            <br>

                            <span class="caption"> Montant appelé </span>
                            <br>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span class="font-weight-bold" v-on="on">
                                        <number :to="getSaleTotalCalled(selectedSale)" :format="$options.filters.toCurrencyString" :duration="1.5" easing="Power4.easeOut" />
                                    </span>
                                </template>
                                Sur un total de {{ getSalePriceTTC(selectedSale) | toCurrencyString() }}
                            </v-tooltip>
                        </v-col>

                        <v-col class="text-center">
                            <v-progress-circular rotate="-90" :size="100" :width="15" :value="displayPercents ? totalPaidPercent : 0" color="app-red">
                                <number :to="totalPaidPercent" :format="$options.filters.floor" :duration="1.5" easing="Power4.easeOut" /> %
                            </v-progress-circular>
                            <br>

                            <span class="caption"> Montant appelé réglé </span>
                            <br>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span class="font-weight-bold" v-on="on">
                                        <number :to="totalPaid" :format="$options.filters.toCurrencyString" :duration="1.5" easing="Power4.easeOut" />
                                    </span>
                                </template>
                                Sur un total de {{ getSaleTotalCalled(selectedSale) | toCurrencyString() }}
                            </v-tooltip>
                        </v-col>

                        <v-col class="text-center">
                            <v-progress-circular rotate="-90" :size="100" :width="15" :value="displayPercents ? amountToPayPercent : 0" color="app-blue">
                                <number :to="amountToPayPercent" :format="$options.filters.floor" :duration="1.5" easing="Power4.easeOut" /> %
                            </v-progress-circular>
                            <br>

                            <span class="caption"> Reste à payer </span>
                            <br>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span class="font-weight-bold" v-on="on">
                                        <number :to="getSalePriceTTC(selectedSale) - totalPaid" :format="$options.filters.toCurrencyString" :duration="1.5" easing="Power4.easeOut" />
                                    </span>
                                </template>
                                Sur un total de {{ getSalePriceTTC(selectedSale) | toCurrencyString() }}
                            </v-tooltip>
                        </v-col>
                    </v-row>

                    <v-timeline>
                        <v-timeline-item v-for="item of getTimeline()" :key="item.id" :color="getItemDotColor(item)" :left="isItemLeft(item)" :right="!isItemLeft(item)" :icon="getItemDotIcon(item)" fill-dot :small="isItemDotSmall(item)" :class="{ 'text-right': isItemLeft(item) }">
                            <template v-slot:opposite>
                                <template v-if="item.itemType === 'callForFunds' && getSaleCallForFundsDocument(selectedSale, item.id)">
                                    <v-btn :href="getDocumentUrl(getSaleCallForFundsDocument(selectedSale, item.id).id)" target="_blank" icon>
                                        <v-icon color="red lighten-1"> mdi-file-pdf-box </v-icon>
                                    </v-btn>
                                </template>
                            </template>

                            <template v-if="item.itemType === 'callForFunds'">
                                <template v-if="item.fundcall.id || item.type === 1">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span class="font-weight-bold grey--text text--darken-2" v-on="on">
                                                <template v-if="item.type === 1">
                                                    {{ selectedSale.signeddate | toDate() }}
                                                </template>
                                                <template v-else>
                                                    {{ item.fundcall.dateSent | toDate() }}
                                                </template>
                                            </span>
                                        </template>
                                        <template v-if="item.type === 1">
                                            Date de signature contrat
                                        </template>
                                        <template v-else>
                                            Date d'envoi
                                        </template>
                                    </v-tooltip>
                                </template>

                                <br>

                                <span class="font-weight-bold">
                                    <template v-if="item.fundcall.amount">
                                        {{ item.fundcall.amount | toCurrencyString() }}
                                    </template>

                                    <v-chip class="ml-2 caption" color="app-blue" small label dark>
                                        {{ item.percent | round() }} %
                                    </v-chip>
                                </span>

                                <br>

                                <span class="subtitle-2 font-weight-light">
                                    <template v-if="item.steps">
                                        <template v-for="step in item.steps">
                                            <span :key="step">
                                                {{ step }}
                                            </span>
                                            <br :key="step + 'br'">
                                        </template>
                                    </template>
                                    <template v-else>
                                        {{ item.step }}
                                    </template>
                                </span>
                            </template>

                            <template v-if="item.itemType === 'tma'">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-if="item.date" class="font-weight-bold grey--text text--darken-2" v-on="on">
                                            {{ item.date | toDate() }}
                                        </span>
                                    </template>
                                    Date de demande
                                </v-tooltip>

                                <br>

                                <span class="font-weight-bold">
                                    {{ item.calledamount | toCurrencyString() }}

                                    <v-chip class="ml-2 caption" color="app-blue" small label dark>
                                        TMA
                                    </v-chip>
                                </span>

                                <br>

                                <span class="subtitle-2 font-weight-light">
                                    {{ item.label }}
                                </span>
                            </template>

                            <template v-if="item.itemType === 'payment'">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-if="item.date" class="font-weight-bold grey--text text--darken-2" v-on="on">
                                            {{ item.date | toDate() }}
                                        </span>
                                    </template>
                                    Date d'encaissement
                                </v-tooltip>

                                <br>

                                <span class="font-weight-bold">
                                    {{ item.amount | toCurrencyString() }}
                                </span>

                                <br>

                                <span class="subtitle-2 font-weight-light">
                                    <template v-if="item.depositId">
                                        Dépôt de garantie
                                    </template>
                                    <template v-else>
                                        <template v-if="item.label">
                                            {{ item.label }}
                                            <v-tooltip v-if="item.labelDetail" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small v-on="on"> fas fa-info-circle </v-icon>    
                                                </template>
                                                {{ item.labelDetail  }}
                                            </v-tooltip>
                                        </template>
                                        <template v-else>
                                            Encaissement
                                        </template>
                                    </template>
                                </span>
                            </template>
                        </v-timeline-item>
                    </v-timeline>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isBefore } from 'date-fns';

import salesMixin from '../mixins/sales';
import documentsMixin from '../mixins/documents';
import callForFundsMixin from '../mixins/callForFunds';
import ProgramSummary from '../components/program/ProgramSummary';

export default {
    name: 'CallForFunds',

    mixins: [salesMixin, documentsMixin, callForFundsMixin],

    components: {
        ProgramSummary
    },

    data: () => ({
        displayPercents: false
    }),

    methods: {
        getItemDotColor(item) {
            if (item.itemType === 'payment') {
                return 'app-red';
            } else if (item.itemType === 'callForFunds') {
                if (item.type === 1 || (item.fundcall && item.fundcall.dateSent)) {
                    return 'app-green';
                } else {
                    return 'grey';
                }
            } else {
                return 'green';
            }
        },

        getItemDotIcon(item) {
            if (item.itemType === 'payment') {
                return 'mdi-send-outline mdi-rotate-180';
            } else if (item.itemType === 'callForFunds') {
                if (item.type === 1) {
                    return 'mdi-draw';
                } else {
                    if (item.isLast) {
                        return 'mdi-key-chain-variant';
                    } else {
                        if (item.fundcall && item.fundcall.id) {
                            if (item.type === 2) {
                                return 'mdi-account-tie-hat';
                            } else {
                                return 'mdi-send-outline';
                            }
                        } else {
                            return '';
                        }
                    }
                }
            } else {
                return 'mdi-ruler-square';
            }
        },

        isItemDotSmall(item) {
            return item.itemType === 'callForFunds' && !item.fundcall.id && item.type !== 1;
        },

        isItemLeft(item) {
            return item.itemType === 'callForFunds' || item.itemType === 'tma';
        },

        getTimeline() {
            let timeline = JSON.parse(JSON.stringify(this.selectedSale.timetable));
            let payments = JSON.parse(JSON.stringify(this.selectedSale.payments));
            let tmas = JSON.parse(JSON.stringify(this.selectedSale.tmas));

            for (let i = 0; i < timeline.length; i++) {
                const item = timeline[i];
                item.isLast = false;
                item.itemType = 'callForFunds';
                if (item.percent === 0 && item.fundcall && item.fundcall.amount > 0) {
                    const percent = item.fundcall.amount / this.getSalePriceTTC(this.selectedSale);
                    item.percent = percent * 100;

                    let sum = 0;
                    const steps = [item.step];
                    for (let j = i + 1; j < timeline.length; j++) {
                        timeline[j].hide = true;
                        steps.push(timeline[j].step);
                        sum += timeline[j].percent;
                        if (sum >= item.percent) {
                            break;
                        }
                    }
                    if (steps.length > 1) {
                        item.steps = steps;
                    }
                }
            }

            if (timeline.length > 0) {
                timeline[timeline.length - 1].isLast = true;
            }

            timeline = timeline.filter((t) => !t.hide);

            for (const payment of payments) {
                const paymentDate = new Date(payment.date);
                let lastbefore = 0;
                for (let i = 0; i < timeline.length; i++) {
                    if (timeline[i].itemType === 'callForFunds') {
                        if (timeline[i].fundcall && timeline[i].fundcall.dateSent) {
                            const dateSent = new Date(timeline[i].fundcall.dateSent);
                            if (isBefore(paymentDate, dateSent)) {
                                payment.itemType = 'payment';
                                timeline.splice(lastbefore + 1, 0, payment);
                                break;
                            }
                            lastbefore = i;
                        }
                    } else
                        lastbefore = i;
                }
                if (!payment.itemType) {
                    payment.itemType = 'payment';
                    timeline.splice(lastbefore + 1, 0, payment);
                }
            }

            for (const tma of tmas) {
                const tmaDate = new Date(tma.date);
                let lastbefore = 0;
                for (let i = 0; i < timeline.length; i++) {
                    if (timeline[i].itemType === 'callForFunds') {
                        if (timeline[i].fundcall && timeline[i].fundcall.dateSent) {
                            const dateSent = new Date(timeline[i].fundcall.dateSent);
                            if (isBefore(tmaDate, dateSent)) {
                                tma.itemType = 'tma';
                                timeline.splice(lastbefore + 1, 0, tma);
                                break;
                            }
                            lastbefore = i;
                        }
                    } else
                        lastbefore = i;
                }
                if (!tma.itemType) {
                    tma.itemType = 'tma';
                    timeline.splice(lastbefore + 1, 0, tma);
                }
            }

            return timeline;
        },

        replaceProductAndProgramChip() {
            const productPlaceholder = document.querySelector('#product-chip');
            productPlaceholder.replaceWith(this.$refs.productChip.$el);
            const programPlaceholder = document.querySelector('#program-chip');
            programPlaceholder.replaceWith(this.$refs.programChip.$el);
        }
    },

    created() {
        this.$nextTick(() => {
            this.displayPercents = true;
            this.replaceProductAndProgramChip();
        });
    }
};
</script>
